import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ICategoryListReqParams, IzcCategoryApiService } from '@iz_commerce/services/api/category-api';
import { BehaviorSubject } from 'rxjs';
import { ICategory } from '@iz_commerce/models/Category';

@Injectable()
export class IzcCategoryDataService {
  protected _list$ = new BehaviorSubject<ICategory[]>([]);

  constructor(protected _categoryApi: IzcCategoryApiService) {}

  public get list() {
    return this._list$.asObservable();
  }

  public fetchList(params?: ICategoryListReqParams) {
    return this._categoryApi.list(params).pipe(tap((data) => {
      this._list$.next(data.results);
    }));
  }

  public create(groupId: number, name: string) {
    return this._categoryApi.create(groupId, name).pipe(tap((data) => {
      this._list$.next([...this._list$.getValue(), data]);
    }));
  }

  public update(id: number, name: string) {
    return this._categoryApi.update(id, name).pipe(tap((data) => {
      this._list$.next(
        this._list$.getValue().map((c) => {
          if (c.id === data.id) {
            return data;
          }
          return c;
        }),
      );
    }));
  }

  public delete(id: number) {
    return this._categoryApi.delete(id).pipe(tap(() => {
      this._list$.next(this._list$.getValue().filter((c) => c.id !== id));
    }));
  }
}
