import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { includes } from 'lodash-es';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar-observables';
import { UserSystemRoles } from '@iz_commerce/models/User';
import { AuthenticationService } from '@iz_shared/services/authentication';
import { ToastService } from '@iz_shared/services/toast';
import { EntityService } from '@iz_shared/views/dashboard/services/entity';
import { ProfileService } from '@iz_shared/services/profile';
import { AuthService } from '@iz_shared/views/login/services/authentication';
import { ParentalConsentService } from '@iz_shared/views/login/services/parental-consent';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public form: FormGroup;
  public submitted: boolean = false;
  public username: AbstractControl;
  public password: AbstractControl;
  public isSession: AbstractControl;
  public isTC: AbstractControl;
  public checkbox: any = 'assets/images/common/empty.svg';
  public nullCheckbox: any = 'assets/images/common/empty.svg';
  public checkboxBoolean: boolean = false;
  public notYet: boolean = false;
  public denied: boolean = false;
  public showModalDenied: boolean = false;
  public isSignInDisabled: boolean = false;
  public isResendLoading: boolean = false;

  public wrongUsername: string;
  public wrongPassword: string;

  public error: any = {};
  public token?: string;
  public isProcessed = false;

  private uid: number;

  constructor(public fb: FormBuilder,
              private auth: AuthService,
              private router: Router,
              public toast: ToastService,
              private entity: EntityService,
              private profileService: ProfileService,
              private slimLoadingBarService: SlimLoadingBarService,
              private authenticationService: AuthenticationService,
              private parentalConsentService: ParentalConsentService,
              private activatedRoute: ActivatedRoute) {
    this.profileService.setGuard(true);
    this.form = fb.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      isSession: [false],
      isTC: [false],
    });

    this.username = this.form.controls.username;
    this.password = this.form.controls.password;
    this.isSession = this.form.controls.isSession;
    this.isTC = this.form.controls.isTC;
  }

  public ngOnInit() {
    localStorage.clear();
    this.profileService.setUser(null);
    this.entity.setEntity(); // FIXME: Check if you need to drop the entity here!
    this.profileService.logOut();
    this.token = this.activatedRoute.snapshot.queryParams.token;
    if (this.token) {
      this.slimLoadingBarService.start();
      this.isProcessed = true;
      const request = this.auth.loginByToken(this.token).subscribe(
        (data) => this.onHandleLoginResult(data, request),
        (err) => {
          this.error = err.error;
          this.toast.setMessage('Auto login error', 'danger');
          this.slimLoadingBarService.complete();
          this.isProcessed = false;
        });
    }
  }

  public onSubmit(values: any): void {
    this.form.markAsPristine();
    this.submitted = true;
    this.error = {};
    if (this.form.valid) {
      this.isSignInDisabled = true;
      this.slimLoadingBarService.start();

      this.notYet = false;
      this.denied = false;
      const request = this.auth.login(values).subscribe((data) => {
        this.onHandleLoginResult(data, request, Boolean(values.isSession));
        this.isSignInDisabled = false;
      },
      (err: HttpErrorResponse) => {
        this.error = err.error;
        this.form.controls.username.setErrors({
          invalidUsername: true,
        });
        this.form.controls.password.setErrors({
          invalidPassword: true,
        });
        if (this.error.non_field_errors) {
          this.toast.setMessage(this.error.non_field_errors[0], 'danger');
        }

        this.slimLoadingBarService.complete();
        this.isSignInDisabled = false;
        if (err.status >= 500 || err.status === 0) {
          this.toast.setMessage('Something went wrong... Try again later...', 'danger');
        }
      });
    } else {
      this.wrongUsername = '';
      this.wrongPassword = '';
    }
  }

  public onHandleLoginResult(data, request, isSession = false) {
    if (request) {
      request.unsubscribe();
    }

    this.authenticationService.setToken({
      access_token: data.access_token,
      refresh_token: data.refresh_token,
    }, isSession);

    this.profileService.setSession(isSession);
    const user = data.user;
    this.uid = user.id;
    this.profileService.setUser(user);
    if (user.role !== UserSystemRoles.SUPER_ADMIN) {
      this.entity.setEntity({
        id: user.entity,
        name: user.entity_name,
        project: user.project
      });
    }

    this.slimLoadingBarService.complete();
    this.isProcessed = false;
    this.toast.setMessage('Login successful', 'success');
    if (user.role === UserSystemRoles.SUPER_ADMIN.toString()) {
      this.router.navigate(['/dashboard/super-admin']);
    } else {
      if (user.terms_of_use) {
        if (user.birth_date) {
          const range = (
            (new Date() as any) - (new Date(user.birth_date.split('T')[0]) as any)
          ) / 1000 / 60 / 60 / 24 / 366;

          const guardianRequiredRoles = [
            UserSystemRoles.DIVISION_MANAGER.toString(),
            UserSystemRoles.DEPARTMENT_MANAGER.toString(),
            UserSystemRoles.WORKGROUP_MANAGER.toString(),
            UserSystemRoles.USER.toString()
          ];
          if (range < 18 && includes(guardianRequiredRoles, user.role)) {
            this.handleMissingGuardian(user);
          } else {
            this.checkRedirect(user);
          }
        } else {
          this.router.navigate(['/date-of-birth']);
        }
      } else {
        this.router.navigate(['/terms-of-service']);
      }
    }
  }

  public sendAnother() {
    this.router.navigate(['/resend-email']);
  }

  public resetValidation() {
    this.error = {};
    this.submitted = false;
    this.form.markAsPristine();
    this.form.controls.username.markAsPristine();
    this.form.controls.password.markAsPristine();
    this.form.controls.username.setErrors(null);
    this.form.controls.password.setErrors(null);
  }

  public resend() {
    this.isResendLoading = true;
    this.parentalConsentService.resendEmail(this.uid).subscribe(() => {
      this.toast.setMessage('Message sent again', 'success');
      this.isResendLoading = false;
    }, (error) => {
      if (error.status === 201) {
        this.toast.setMessage('Message sent again', 'success');
        this.showModalDenied = false;
      } else {
        this.toast.setMessage('Message not sent again', 'danger');
        this.showModalDenied = false;
      }
      this.isResendLoading = false;
    });
  }

  public stateCheckbox(state) {
    this.checkboxBoolean = !state;
    if (this.checkboxBoolean) {
      this.checkbox = 'assets/images/common/check-mark.svg';
    } else {
      this.nullCheckbox = 'assets/images/common/empty.svg';
    }
  }

  private checkRedirect(user: any) {
    if (user.survey) {
      if (user.role === UserSystemRoles.USER.toString()) {
        this.router.navigate(['/dashboard/innerzone-profile']);
      } else {
        this.router.navigate(['/dashboard/management']);
      }
    } else {
      this.router.navigate(['/dashboard/questionnaire']);
    }
  }

  private handleMissingGuardian(user) {
    // guardian info is not provided
    if (user.guardian_acceptance === null && !user.guardian_email) {
      this.router.navigate(['/parental-consent']);
      return;
    }

    // guardian info is provided, but terms are not accepted
    if (user.guardian_acceptance === null) {
      this.notYet = true;
      this.showModalDenied = true;
      return;
    }

    // guardian accepted/denied terms
    if (user.guardian_acceptance) {
      this.checkRedirect(user);
    } else {
      this.denied = true;
    }
  }
}
