import { Component, EventEmitter, Input, Output } from '@angular/core';
/* Vendor */
import { merge } from 'lodash-es';
/* Models */
import { User } from '@iz_commerce/models/User';
import { RegistrationService } from '@iz_shared/views/registration/services/registration';

@Component({
  selector: 'app-registration-first-step',
  templateUrl: './registration-first-step.component.html',
  styleUrls: ['./registration-first-step.component.scss'],
})
export class RegistrationFirstStepComponent {
  @Input() public user: User;
  @Output() public onNext = new EventEmitter<User>();
  public error: any = {};
  public errorFlag: boolean = false;
  public isNextLoading: boolean = false;

  constructor(private registration: RegistrationService) {
    this.errorFlag = false;
  }

  public setTitle(title: any) {
    this.user.title = title.value;
  }

  public onSubmit(form: any) {
    this.error = {};
    if (form.valid) {
      this.isNextLoading = true;
      this.registration.checkEntity(form.value.entity_name).subscribe(
        () => {
          this.isNextLoading = false;
          this.onNext.emit(merge(this.user, form.value));
        },
        (err: any) => {
          if (err.status === 400) {
            this.error = err.error;
            this.errorFlag = true;
          }
          this.isNextLoading = false;
        },
      );
    }
  }
}
