import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-registration-title',
  templateUrl: './registration-title.component.html',
  styleUrls: ['./registration-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationTitleComponent {
  @Input() public title: string = '';
  @Input() public subtitle: string = '';
  @Input() public src: string = '';

  constructor() {}

}
