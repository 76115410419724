import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '@iz_shared/models/User';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PromoApiService } from '@iz_commerce/services/api/promo-api';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileService } from '@iz_shared/services/profile';
import { AuthenticationService } from '@iz_shared/services/authentication';
import { ToastService } from '@iz_shared/services/toast';
import { omit } from 'lodash-es';
import { IzUserFormsService } from '@iz_shared/views/dashboard/services/user-forms';

@Component({
  selector: 'izc-signup-by-link',
  templateUrl: './signup-by-link.component.html',
  styleUrls: ['./signup-by-link.component.scss'],
})
export class SignupByLinkComponent implements OnInit {
  public isAgree = false;
  public formData = {} as User;
  public isProcessing = false;
  public isCheckTokenProcessing = true;
  public linkIsValid = false;
  public touIsOpen = false;
  public regexPassword: RegExp = IzUserFormsService.regexPassword;
  public fieldErrors = {
    required: 'This field is required',
    server_error: '',
    email: 'Enter a valid email',
    minlength: 'Min length is 6',
  };
  public passwordErrors = {
    required: 'This field is required',
    server_error: '',
    minlength: 'login.passwordReq.minlength',
    maxlength: 'login.passwordReq.maxlength',
    pattern: 'login.passwordReq.pattern'
  };

  public formError?;

  public titles = [
    { name: 'Mr.', value: 'mr' },
    { name: 'Mrs.', value: 'mrs' },
    { name: 'Miss', value: 'miss' },
    { name: 'Ms.', value: 'ms' },
  ];
  @ViewChild('modalTermsOfUse') private modal: any;
  @ViewChild('form') private form: NgForm;
  private registrationToken?: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private promoApi: PromoApiService,
    private profileService: ProfileService,
    private authenticationService: AuthenticationService,
    private toastr: ToastService,
  ) {}

  public ngOnInit() {
    const token = this.route.snapshot.queryParams.token;
    if (!token) {
      this.linkIsValid = false;
      this.isCheckTokenProcessing = false;
    } else {
      this.promoApi.getRegistrationToken(token).subscribe(
        (result) => {
          this.registrationToken = result.token;
          this.linkIsValid = true;
          this.isCheckTokenProcessing = false;
        },
        () => {
          this.linkIsValid = false;
          this.isCheckTokenProcessing = false;
        },
      );
    }
  }

  public get needPhone() {
    return this.route.snapshot.queryParams.phone === 'true';
  }

  public onTerms(shouldOpen: boolean) {
    this.touIsOpen = shouldOpen;
  }

  public clearServerErrors() {
    this.formError = void 0;
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.controls[key];
      const errors = omit(control.errors, 'serverError');
      // empty object is error for controller, only null is for valid
      control.setErrors(Object.keys(errors).length ? errors : null);
    });
  }

  public getErrors(key: string) {
    if (this.formError && this.formError[key]) {
      this.form.controls[key].setErrors({ serverError: true });
      return { ...this.fieldErrors, serverError: this.formError[key].join('; ') };
    }
    return this.fieldErrors;
  }

  public onSubmit(form: NgForm) {
    if (!this.isAgree) {
      this.toastr.setMessage('Confirm Terms of User', 'danger');
      this.form.controls.isAgree.setErrors({'required': true});
    }
    if (form.valid && this.isAgree) {
      this.isProcessing = true;
      this.promoApi
        .createUser({
          ...form.value,
          token: this.registrationToken,
        })
        .subscribe(
          (result) => {
            this.authenticationService.setToken(
              {
                access_token: result.access_token,
                refresh_token: result.refresh_token,
              },
              true,
            );
            this.profileService.setSession(true);
            this.profileService.setUser(result.user);
            this.router.navigate(['/terms-of-service']);
            this.toastr.setMessage('Success', 'success');
            this.isProcessing = false;
          },
          (error: HttpErrorResponse) => {
            this.formError = error.error ? error.error.user : void 0;
            this.isProcessing = false;
            if (error.status !== 400) {
              this.toastr.setMessage('Ooppps... Something went wrong', 'danger');
            }
          },
          () => {},
        );
    }
  }
}
