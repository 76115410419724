import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { ProfileService } from '@iz_shared/services/profile';
import { PromoApiService } from '@iz_commerce/services/api/promo-api';
import { ProfilePromoService } from '@iz_commerce/services/util/profile-promo';
import { IPromoStatus } from '@iz_commerce/models/PromoStatus';
import { UserSystemRoles } from '@iz_commerce/models/User';

@Injectable()
export class ProfilePromoGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private promoApi: PromoApiService,
    private profilePromoService: ProfilePromoService,
    private router: Router,
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.profileService.getUser().pipe(
      take(1),
      mergeMap((user: any) => {
        if (user.role !== UserSystemRoles.USER.toString()) {
          return of(true);
        }

        return this.promoApi.checkPromoStatus(user.id).pipe(map((status: IPromoStatus) => {
          if (status.promo) {
            this.profilePromoService.setProfileStatus(status);
            this.router.navigate([next.data.promoProfilePath]);

            return false;
          }

          return true;
        }));
      }));
  }
}
