import { NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IzcEmailApiService } from './email-api';
import { IzcCategoryApiService } from './category-api';
import { PromoApiService } from './promo-api';

@NgModule({
  imports: [CommonModule],
  providers: [
    IzcEmailApiService,
    IzcEmailApiService.di,
    IzcCategoryApiService,
    // IzcCategoryApiMockService,
    // IzcCategoryApiMockService.di,
    PromoApiService,
  ],
  declarations: [],
})
export class IzcApiModule {}
