import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/* Main component */
import { LoginComponent } from './login.component';

/* Components */
import {
  ForgotPasswordCheckStepComponent,
  ForgotPasswordResetPasswordComponent,
  ForgotPasswordUsernameStepComponent,
  LoginConfirmationParentsComponent,
  LoginDateOfBirthComponent,
  LoginForgotPasswordComponent,
  LoginForgotUsernameComponent,
  LoginParentalConsentComponent,
  LoginResendEmailComponent,
  ParentalConsentFormStepComponent,
  ParentalConsentWaitStepComponent,
  ResendEmailFormStepComponent,
  ResendEmailWaitStepComponent,
} from '.';

/* Modules */
import { AppTranslationModule } from '@iz_commerce/app.translation.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { IzcCommonModule } from '@iz_commerce/common';
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar-observables';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IzLoginModule, IzLoginServicesModule } from '@iz_shared/views/login';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    IzcCommonModule,
    IzCommonModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    AppTranslationModule,
    AngularSvgIconModule.forRoot(),
    SlimLoadingBarModule.forRoot(),
    IzLoginServicesModule,
    IzLoginModule,
  ],
  declarations: [
    LoginComponent,
    LoginForgotUsernameComponent,
    ForgotPasswordResetPasswordComponent,

    LoginForgotPasswordComponent,
    ForgotPasswordUsernameStepComponent,
    ForgotPasswordCheckStepComponent,

    LoginParentalConsentComponent,
    ParentalConsentFormStepComponent,
    ParentalConsentWaitStepComponent,

    LoginResendEmailComponent,
    ResendEmailFormStepComponent,
    ResendEmailWaitStepComponent,

    LoginConfirmationParentsComponent,
    LoginDateOfBirthComponent,
  ],
  bootstrap: [LoginComponent],
})
export class LoginModule {}
