import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Main component */
import { NavbarComponent } from './navbar.component';

/* Components */
import { ProfileMenuComponent } from './components/profile-menu';
import { CurrentEntityComponent } from './components/current-entity';
import { SearchUserComponent } from './components/search-user';

/* Modules */
import { ModalModule } from '@iz_shared/components/modal';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { IzcCommonModule } from '@iz_commerce/common';
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { AppTranslationModule } from '@iz_commerce/app.translation.module';

/* Services */
import { EntityService } from '@iz_shared/views/dashboard/services/entity';
import { GlobalSearchService } from '@iz_shared/views/dashboard/services/global-search';

@NgModule({
  imports: [
    ModalModule,
    FormsModule,
    CommonModule,
    NgScrollbarModule,
    IzcCommonModule,
    IzCommonModule,
    ReactiveFormsModule,
    AppTranslationModule,
  ],
  declarations: [NavbarComponent, ProfileMenuComponent, CurrentEntityComponent, SearchUserComponent],
  exports: [NavbarComponent],
  providers: [EntityService, GlobalSearchService],
  bootstrap: [NavbarComponent],
})
export class NavbarModule {}
