import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/* Main component */
import { PagesComponent } from './pages.component';

/* Modules */
import { IzcCommonModule } from '@iz_commerce/common';
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { DashboardModule } from './dashboard';
import { LoginModule } from './login/login.module';
import { RegistrationModule } from './registration';
import { PagesRoutingModule } from './pages.routing';
import { AppTranslationModule } from '@iz_commerce/app.translation.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

/* Components */
import { PageNotFoundComponent } from './page-not-found';
import { PageTermsOfServiceComponent } from './page-terms-of-service';
/* Service */
import { IzProfileActivateService } from '@iz_shared/views/dashboard/modules/iz-profile/services/iz-activate';
import { SignupByLinkComponent } from './signup-by-link';
import { TermsOfServiceModule } from '@iz_commerce/pages/terms-of-service/terms-of-service.module';

@NgModule({
  imports: [
    LoginModule,
    RouterModule,
    DashboardModule,
    IzcCommonModule,
    IzCommonModule,
    NgScrollbarModule,
    PagesRoutingModule,
    RegistrationModule,
    AppTranslationModule,
    TermsOfServiceModule,
  ],
  declarations: [
    PagesComponent,
    PagesComponent,
    PageNotFoundComponent,
    PageTermsOfServiceComponent,
    SignupByLinkComponent,
  ],
  exports: [TermsOfServiceModule],
  providers: [
    IzProfileActivateService,
  ],
  bootstrap: [PagesComponent],
})
export class PagesModule {
}
