import { UserSystemRoles } from '@iz_commerce/models/User';
import { IMenuItemConfig, MenuPositions } from '@iz_shared/models/Configs';

export const MenuSettings: IMenuItemConfig[] = [
  {
    link: 'management',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString(),
      UserSystemRoles.DIVISION_MANAGER.toString(),
      UserSystemRoles.DEPARTMENT_MANAGER.toString(),
      UserSystemRoles.WORKGROUP_MANAGER.toString()
    ],
    icon: {
      default: 'assets/images/menu/sports-management.svg',
      active: 'assets/images/menu/sports-management-active.svg',
    },
    title: 'dashboard.menu.sportsManagement',
    position: MenuPositions.TOP,
  },
  {
    link: 'email-messages',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString(),
      UserSystemRoles.DIVISION_MANAGER.toString(),
      UserSystemRoles.DEPARTMENT_MANAGER.toString(),
      UserSystemRoles.WORKGROUP_MANAGER.toString()
    ],
    icon: {
      default: 'assets/images/menu/email-messages.svg',
      active: 'assets/images/menu/email-messages-active.svg',
    },
    title: 'dashboard.menu.emailMessages',
    position: MenuPositions.TOP,
  },
  {
    link: 'reports',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString(),
      UserSystemRoles.DIVISION_MANAGER.toString(),
      UserSystemRoles.DEPARTMENT_MANAGER.toString(),
      UserSystemRoles.WORKGROUP_MANAGER.toString()
    ],
    icon: {
      default: 'assets/images/menu/reports.svg',
      active: 'assets/images/menu/reports-active.svg',
    },
    title: 'dashboard.menu.reports',
    position: MenuPositions.TOP,
  },
  {
    link: 'tier-management',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString()
    ],
    icon: {
      default: 'assets/images/menu/dept-management.svg',
      active: 'assets/images/menu/dept-management-active.svg',
    },
    title: 'dashboard.menu.tierManagement',
    position: MenuPositions.TOP,
  },
  {
    link: 'admins',
    role: [UserSystemRoles.SUPER_ADMIN.toString()],
    icon: {
      default: 'assets/images/menu/admin.svg',
      active: 'assets/images/menu/admin-active.svg',
    },
    title: 'dashboard.menu.admin',
    position: MenuPositions.TOP,
  },
  /*{
    link: 'teams',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString()
    ],
    icon: {
      default: 'assets/images/menu/my-team.svg',
      active: 'assets/images/menu/my-team-active.svg',
    },
    title: 'dashboard.menu.team',
    position: MenuPositions.BOTTOM,
  },*/
  {
    link: 'super-admin',
    role: [UserSystemRoles.SUPER_ADMIN.toString()],
    icon: {
      default: 'assets/images/menu/super-admins.svg',
      active: 'assets/images/menu/super-admins-active.svg',
    },
    title: 'dashboard.menu.superAdmin',
    position: MenuPositions.BOTTOM,
  },
];
