/**
 * @deprecated
 */
export default {
  user: [],
  headuser: [
    {
      url: 'assets/images/menu/sports-management.svg',
      url_active: 'assets/images/menu/sports-management-active.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      url_active: 'assets/images/menu/email-messages-active.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      url_active: 'assets/images/menu/reports-active.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
  ],
  manager: [
    {
      url: 'assets/images/menu/sports-management.svg',
      url_active: 'assets/images/menu/sports-management-active.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      url_active: 'assets/images/menu/email-messages-active.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      url_active: 'assets/images/menu/reports-active.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
  ],
  headmanager: [
    {
      url: 'assets/images/menu/sports-management.svg',
      url_active: 'assets/images/menu/sports-management-active.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      url_active: 'assets/images/menu/email-messages-active.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      url_active: 'assets/images/menu/reports-active.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
  ],
  entityadmin: [
    {
      url: 'assets/images/menu/sports-management.svg',
      url_active: 'assets/images/menu/sports-management-active.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      url_active: 'assets/images/menu/email-messages-active.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      url_active: 'assets/images/menu/reports-active.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
    {
      url: 'assets/images/menu/dept-management.svg',
      url_active: 'assets/images/menu/dept-management-active.svg',
      title: 'dashboard.menu.tierManagement',
      link: 'tier-management',
    },
  ],
  admin: [
    {
      url: 'assets/images/menu/sports-management.svg',
      url_active: 'assets/images/menu/sports-management-active.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      url_active: 'assets/images/menu/email-messages-active.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      url_active: 'assets/images/menu/reports-active.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
    {
      url: 'assets/images/menu/dept-management.svg',
      url_active: 'assets/images/menu/dept-management-active.svg',
      title: 'dashboard.menu.tierManagement',
      link: 'tier-management',
    },
    {
      url: 'assets/images/menu/admin.svg',
      title: 'dashboard.menu.admin',
      link: 'admins',
    },
  ],
  superAdmin: {
    url: 'assets/images/menu/super-admins.svg',
    url_active: 'assets/images/menu/super-admins-active.svg',
    title: 'dashboard.menu.superAdmin',
    link: 'super-admin',
  },
};
