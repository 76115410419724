import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPromoStatus } from '@iz_commerce/models/PromoStatus';

/*
  The goal of the service is sharing promo profile data between profile-promo guard and profile-promo component
 */
@Injectable()
export class ProfilePromoService {
  private profileStatus: BehaviorSubject<IPromoStatus | null> = new BehaviorSubject(null);

  constructor() {}

  public get status() {
    return this.profileStatus.asObservable();
  }

  public setProfileStatus(status: IPromoStatus | null) {
    this.profileStatus.next(status);
  }

  // util method to know whether the status was set
  public isEmpty(): boolean {
    return !this.profileStatus.getValue();
  }
}
