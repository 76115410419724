import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/* Main component */
import { RegistrationComponent } from './registration.component';
/* Components */
import { RegistrationStepsComponent } from './components/registration-steps';
import { RegistrationTitleComponent } from './components/registration-title';
import { RegistrationFirstStepComponent } from './components/registration-first-step';
import { RegistrationThirdStepComponent } from './components/registration-third-step';
import { ConfirmTermsOfServiceComponent } from './components/confirm-terms-of-service';
import { RegistrationSecondStepComponent } from './components/registration-second-step';
/* Modules */
import { IzcCommonModule } from '@iz_commerce/common';
import { AppTranslationModule } from '@iz_commerce/app.translation.module';
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { IzRegistrationModule, IzRegistrationServicesModule } from '@iz_shared/views/registration';
import { TermsOfServiceModule } from '@iz_commerce/pages/terms-of-service/terms-of-service.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    IzcCommonModule,
    IzCommonModule,
    ReactiveFormsModule,
    AppTranslationModule,
    IzRegistrationServicesModule,
    IzRegistrationModule,
    TermsOfServiceModule,
  ],
  declarations: [
    RegistrationComponent,
    RegistrationTitleComponent,
    RegistrationStepsComponent,
    ConfirmTermsOfServiceComponent,
    RegistrationFirstStepComponent,
    RegistrationThirdStepComponent,
    RegistrationSecondStepComponent,
  ],
  bootstrap: [RegistrationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RegistrationModule {}
