import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* Main component */
import { DashboardComponent } from './dashboard.component';
/* Components */
import { MenuComponent } from './components/menu';
import { PlayerListSubMenuComponent } from './components/player-list-sub-menu';
import { PlayerBoardSubMenuComponent } from './components/player-board-sub-menu';
import { ManagerBoardSubMenuComponent } from './components/manager-board-sub-menu';
/* Modules */
import { IzcCommonModule } from '@iz_commerce/common';
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { NavbarModule } from './components/navbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DashboardRoutingModule } from './dashboard.routing';
import { AppTranslationModule } from '@iz_commerce/app.translation.module';
/* Services */
import { IzDashboardModule, IzDashboardServicesModule } from '@iz_shared/views/dashboard';
import { IzcDashboardServicesModule } from './services';

@NgModule({
  imports: [
    NavbarModule,
    CommonModule,
    IzCommonModule,
    NgScrollbarModule,
    IzcCommonModule,
    IzDashboardModule,
    AppTranslationModule,
    DashboardRoutingModule,
    IzDashboardServicesModule,
    IzcDashboardServicesModule,
  ],
  declarations: [
    MenuComponent,
    DashboardComponent,
    PlayerListSubMenuComponent,
    PlayerBoardSubMenuComponent,
    ManagerBoardSubMenuComponent,
  ],
})
export class DashboardModule {
}
