import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IzcApiModule } from './api';
import { IzcUtilModule } from '@iz_commerce/services/util/izc-util.module';

@NgModule({
  imports: [CommonModule, IzcApiModule, IzcUtilModule],
  providers: [],
  declarations: [],
})
export class IzcSharedServicesModule {
}
