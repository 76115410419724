import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApiRes } from '@iz_commerce/models/Response';
import { ICategory } from '@iz_commerce/models/Category';
import { normalizeParams } from '@iz_shared/services/utils';

export interface ICategoryListReqParams {
  group: number;
}

@Injectable()
export class IzcCategoryApiService {
  constructor(private http: HttpClient) {}

  public list(params?: ICategoryListReqParams) {
    return this.http.get<IApiRes<ICategory[]>>('/api/group_categories/', {
      params: normalizeParams(params),
    });
  }

  public create(groupId: number, name: string) {
    return this.http.post<ICategory>('/api/group_categories/', { group: groupId, name });
  }

  public update(id: number, name: string) {
    return this.http.patch<ICategory>(`/api/group_categories/${id}/`, {name});
  }

  public delete(id: number) {
    return this.http.delete(`/api/group_categories/${id}/`);
  }
}
