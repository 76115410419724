/* Main modules */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Components for router */
import { PagesComponent } from './pages.component';
import { PageNotFoundComponent } from './page-not-found';
import { RegistrationComponent } from './registration';
import { PageTermsOfServiceComponent } from './page-terms-of-service';
import { TermsOfServiceComponent } from './terms-of-service';
import {
  ForgotPasswordResetPasswordComponent,
  LoginComponent,
  LoginConfirmationParentsComponent,
  LoginDateOfBirthComponent,
  LoginForgotPasswordComponent,
  LoginForgotUsernameComponent,
  LoginParentalConsentComponent,
  LoginResendEmailComponent,
} from './login';

/* Service */
import { IzProfileActivateService } from '@iz_shared/views/dashboard/modules/iz-profile/services/iz-activate';
import { SignupByLinkComponent } from '@iz_commerce/pages/signup-by-link';
import { AuthGuard } from '@iz_shared/services/auth-guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [IzProfileActivateService],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        component: PageTermsOfServiceComponent,
        path: 'terms-of-service-confirm',
        canActivate: [AuthGuard],
      },
      {
        component: TermsOfServiceComponent,
        path: 'registration-terms-of-service',
        data: {
          accept: false,
        },
      },
      {
        component: TermsOfServiceComponent,
        path: 'terms-of-service',
        data: {
          accept: true,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: LoginForgotPasswordComponent,
      },
      {
        path: 'forgot-username',
        component: LoginForgotUsernameComponent,
      },
      {
        path: 'date-of-birth',
        component: LoginDateOfBirthComponent,
      },
      {
        path: 'resend-email',
        component: LoginResendEmailComponent,
      },
      {
        path: 'parental-consent',
        component: LoginParentalConsentComponent,
      },
    ],
  },
  {
    path: 'reset-password',
    component: PagesComponent,
    children: [
      {
        path: '',
        component: ForgotPasswordResetPasswordComponent,
      },
    ],
  },
  {
    path: 'confirmation-parents',
    component: PagesComponent,
    children: [
      {
        path: '',
        component: LoginConfirmationParentsComponent,
      },
    ],
  },
  {
    path: 'registration',
    component: PagesComponent,
    children: [
      {
        path: '',
        component: RegistrationComponent,
      },
    ],
  },
  {
    path: 'signup-by-link',
    component: PagesComponent,
    children: [
      {
        path: '',
        component: SignupByLinkComponent,
      },
    ],
  },
  {
    component: PageNotFoundComponent,
    path: '**',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
