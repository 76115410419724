import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* Main component */
import { TermsOfServiceComponent } from './terms-of-service.component';
/* Modules */
import { AppTranslationModule } from '@iz_commerce/app.translation.module';
import { IzCommonModule } from '@iz_shared/iz-common.module';

@NgModule({
  imports: [
    CommonModule,
    AppTranslationModule,
    IzCommonModule,
  ],
  declarations: [TermsOfServiceComponent],
  exports: [TermsOfServiceComponent],
  bootstrap: [TermsOfServiceComponent],
})
export class TermsOfServiceModule {}
