import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
/* Vendor */
import { merge } from 'lodash-es';
/* Models */
import { User } from '@iz_commerce/models/User';
import { Router } from '@angular/router';
import { UserApiService } from '@iz_shared/services/api/user-api';
import { IzUserFormsService } from '@iz_shared/views/dashboard/services/user-forms';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-registration-third-step',
  templateUrl: './registration-third-step.component.html',
  styleUrls: ['./registration-third-step.component.scss'],
})
export class RegistrationThirdStepComponent implements OnChanges {
  @Input() public error: any;
  @Input() public user: User;
  @Input() public isLoading: boolean = false;
  @Output() public onBack = new EventEmitter<User>();
  @Output() public onNext = new EventEmitter<User>();
  @Output() public onTermsClick = new EventEmitter();
  @ViewChild('form') private form: any;
  public regexPassword: RegExp = IzUserFormsService.regexPassword;
  public passwordErrors = {
    required: 'This field is required',
    server_error: '',
    minlength: 'login.passwordReq.minlength',
    maxlength: 'login.passwordReq.maxlength',
    pattern: 'login.passwordReq.pattern'
  };

  constructor(private router: Router, private userApi: UserApiService) {
  }

  public ngOnChanges() {
    if (this.error && this.error.username) {
      this.form.form.submitted = true;
      this.form.submitted = true;
      this.form.form.controls.username.setErrors({
        invalidLogin: true,
      });
    }
  }

  public onEditUserName(form: any) {
    form.control.controls.username.markAsPristine();
  }

  public checkEquals(form: any) {
    form.control.controls.verifyPassword.markAsPristine();

    if (form.value.password !== form.value.verifyPassword) {
      form.control.controls.verifyPassword.setErrors({
        invalidPassword: true,
      });
    }
  }

  public onSubmit(form: any) {
    if (form.valid) {
      this.error = void 0;
      this.isLoading = true;
      this.userApi.usernameValidation(this.user.username).subscribe((unique) => {
        if (unique) {
          this.onNext.emit(merge(this.user, form.value));
          this.error = void 0;
        } else {
          this.error = { username: ['Username name is already used.'] };
        }
      }, () => {
        this.error = void 0;
      }, () => {
        this.isLoading = false;
      });
    }
  }

  public toBackStep(form: any) {
    this.onBack.emit(merge(this.user, form.value));
  }

  public toTerms() {
    this.onTermsClick.emit();
  }

  public getError(control: AbstractControl) {
    const errorsDict = {
      minlength: 'Minimum length of 8 characters',
      maxlength: 'Maximum length of 64 characters',
      required: 'All fields required.',
      invalidPassword: 'New Password and Repeat Password do not match.',
      pattern: 'Missing an uppercase, lowercase, number, or special character'
    };
    if (!control.errors || Object.keys(control.errors).length === 0) {
      return [];
    }
    return Object.keys(control.errors).map((key) => errorsDict[key]).join('; ');
  }
}
