import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@iz_shared/models/User';
import { IPromoStatus } from '@iz_commerce/models/PromoStatus';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver-es';
import { tap } from 'rxjs/operators';
import { Group } from '@iz_shared/models/Group';

export interface IPromoUser {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  password: string;
  title: string;
  token: string;
}

@Injectable()
export class PromoApiService {
  constructor(private http: HttpClient) {
  }

  public getRegistrationToken(token: string) {
    return this.http.post<{ token: string }>('/api/groups/token_validation/', { token });
  }

  public createUser(user: IPromoUser) {
    return this.http.post<{
      access_token: string;
      refresh_token: string;
      user: User;
    }>('/api/groups/user_registration/', user);
  }

  public checkPromoStatus(id: number): Observable<IPromoStatus> {
    return this.http.get<IPromoStatus>(`/api/users/${id}/promo_user/`);
  }

  public saveExcelReport(name: string, id: Group['id']) {
    return this.http.get(`/api/groups/${id}/promo_excel/`, {
      responseType: 'blob',
    }).pipe(
      tap((data) => {
        saveAs(data, `${name}.xlsx`);
      }),
    );
  }
}
