import { Injectable, Injector, Provider } from '@angular/core';
import { EmailDataService } from '@iz_shared/views/dashboard/services/email-data';

@Injectable()
export class IzcDEmailDataService extends EmailDataService {
  public static di: Provider = {
    provide: EmailDataService,
    useExisting: IzcDEmailDataService,
  };

  constructor(injector: Injector) {
    super(injector);
  }
}
