import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@iz_shared/services/toast';
import { ParentalConsentService } from '@iz_shared/views/login/services/parental-consent';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-confirmation-parents-login',
  templateUrl: './confirmation-parents.component.html',
  styleUrls: ['./confirmation-parents.component.scss'],
})
export class LoginConfirmationParentsComponent {
  public isSuccess = false;
  public isConfirmLoading: boolean = false;

  public terms: string = '';
  private token: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private parent: ParentalConsentService,
    public toast: ToastService,
    private http: HttpClient,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.token = params.token;
    });
    this.http
      .get('/static/terms_of_service.html', {
        responseType: 'text',
      })
      .subscribe((data) => {
        this.terms = data;
      });
  }

  public toLogin(check: string): void {
    this.isConfirmLoading = true;
    this.parent
      .updateAccess({
        token: this.token,
        acceptance: check,
      })
      .subscribe(
        () => {
          // this.router.navigate(['/login']);
          this.isSuccess = true;
          this.toast.setMessage('Access updated', 'success');
          this.isConfirmLoading = false;
        },
        (err) => {
          if (err.status === 200) {
            this.toast.setMessage('Access updated', 'success');
            this.isSuccess = true;
            // this.router.navigate(['/login']);
            this.isConfirmLoading = false;
          } else {
            this.toast.setMessage(err.error.token[0] || err.error.detail, 'danger');
            this.router.navigate(['/login']);
            this.isConfirmLoading = false;
          }
        },
      );
  }

  public navigateToLogin() {
    this.router.navigate(['/login']);
  }
}
