import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

/* Services */
import { ToastService } from '@iz_shared/services/toast';
/* Models */
import { User } from '@iz_commerce/models/User';
import { ProfileService } from '@iz_shared/services/profile';
import { MenuSettings } from './configs/Menu.configs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewChecked, OnInit {
  public user = new User();
  public isActiveMenu: boolean = true;
  public menu_settings = MenuSettings;
  @ViewChild('view') public view: ElementRef;

  constructor(
    private router: Router,
    public toast: ToastService,
    private profile: ProfileService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.isActiveMenu = this.checkTargetWidth(window);
    window.addEventListener('resize', ($event) => {
      this.isActiveMenu = this.checkTargetWidth($event.currentTarget);
    });
  }

  public ngOnInit() {
    this.profile.getUser().subscribe((user: any) => {
      this.user = user;
    });
  }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public setActivate(link: string) {
    this.isActiveMenu = this.checkTargetWidth(window) ? this.isActiveMenu : false;
    if (link && !this.router.url.includes(`/${link}`)) {
      this.router.navigate(['dashboard/' + link]);
    }
  }

  public toggleMenu() {
    this.isActiveMenu = !this.isActiveMenu;
  }

  private checkTargetWidth(target) {
    return target.innerWidth > 1550;
  }
}
