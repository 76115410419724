import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IzcDEmailDataService } from './email-data';
import { IzcGroupDataService } from './group-data';
import { IzcCategoryDataService } from './category-data';
import { ProfilePromoGuard } from './profile-promo-guard';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    IzcDEmailDataService,
    IzcDEmailDataService.di,
    IzcGroupDataService,
    IzcGroupDataService.di,
    IzcCategoryDataService,
    ProfilePromoGuard,
  ],
})
export class IzcDashboardServicesModule {}
