import {tap} from 'rxjs/operators';
import { Injectable, Injector, Provider } from '@angular/core';
import { GroupDataService } from '@iz_shared/views/dashboard/services/group-data';
import { Group } from '@iz_commerce/models/Group';

@Injectable()
export class IzcGroupDataService extends GroupDataService {
  public static di: Provider = {
    provide: GroupDataService,
    useExisting: IzcGroupDataService,
  };

  constructor(injector: Injector) {
    super(injector);
  }

  public deleteGroup(id: number, parentGroupId?: number) {
    return this.groupApi.delete(id).pipe(tap(() => {
      let result = this._list$.getValue().filter((el) => el.id !== id);
      if (parentGroupId) {
        result = result.map((g: any) => {
          if (g.id === parentGroupId) {
            (g as Group).children = (g as Group).children.filter((el) => el === id);
          }
          return g;
        });
      }
      this._list$.next(result);
    }));
  }

  public addGroup(group: any, params?: { entity: number }, parentId?: number) {
    return this.groupApi.create(group, params).pipe(tap((data) => {
      let result = ([...this._list$.getValue(), data] as any) as Group[];
      if (parentId) {
        result = result.map((g) => {
          if (g.id === parentId) {
            g.children = g.children ? [...g.children, data.id] : [data.id];
          }
          return g;
        });
      }
      this._list$.next(result as any);
    }));
  }
}
